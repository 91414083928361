import React, { useState, useEffect, useContext } from 'react';
import { getCurrentUser } from '../../utils/getData/User';
import { useParams, Navigate, useLocation } from 'react-router-dom';
import { PlayBefore } from './PlayBefore'
import PlayAfter from './PlayAfter';
import { LoadingScreen } from '../loading/LoadingScreen';
import getLocationTeam from '../../utils/getData/LocationTeam';
import { getProductValiationList } from '../../utils/getData/ProductValiationList';
import AuthContext from '../../utils/auth/AuthContext';
import Logout from '../auth/Logout';
const GachaPlay: React.FC = () => {
  const { authentication } = useContext(AuthContext);
  const [tickets, setTickets] = useState(0);
  const { team = "default" } = useParams();
  const { product_code = "default" } = useParams();
  const [loading, setLoading] = useState(true);
  // 将来的にカード以外のものを販売する場合、商品タイプを取得し、演出などをこれで切り替える予定
  const [type, setType] = useState("Card");
  const [productValiationId, setPoductValiationId] = useState("");
  const [rarityName, setRarityName] = useState("");
  const [productValiationName, setProductValiationName] = useState("");
  const [isValidTeam, setIsValidTeam] = useState<boolean>(true);
  const [isValidProduct, setIsValidProduct] = useState<boolean>(true);
  const [rareEffect, setRareEffect] = useState("");
  var teamList: Object;
  if (typeof process.env.REACT_APP_TEAMNAMES === "string") {
    teamList = JSON.parse(process.env.REACT_APP_TEAMNAMES);
  } else {
    teamList = [];
  }


  const [teamColor, setTeamColor] = useState("default");
  const location = useLocation();

  useEffect(() => {
    setLoading(true);

    // 存在するTeanかチェック
    if (!(team in teamList)) {
      setIsValidTeam(false);
    }
    // 存在するProductCodeかチェック
    const checkProductCode = async () => {
      try {
        await getProductValiationList(product_code)
      } catch {
        setIsValidProduct(false);
      }
    }

    const fetchUserTickets = async () => {
      try {
        const user = await getCurrentUser();
        setTickets(user.tickets);
        setLoading(false);
      } catch {
        return <Logout reason="通信に失敗しました。再度ログインしてください。この画面が繰り返し表示される場合、Cookieを削除してください。" />
      }
    }
    const teamName = getLocationTeam(location);
    setTeamColor(teamName);

    if (authentication) {
      fetchUserTickets();
    }
    checkProductCode();
    setLoading(false);
  }, []);

  if (!isValidTeam || !isValidProduct) {
    return <Navigate to="/" />;
  } else if (loading) {
    return <LoadingScreen />;
  } else {
    return (
      <div className='s-reset'>
        {
          productValiationId === "" ?
            <PlayBefore teamColor={teamColor} tickets={tickets} teamName={team} product_code={product_code} setProductValiationId={setPoductValiationId} setRarityName={setRarityName} setProductValiationName={setProductValiationName} setRareEffect={setRareEffect} setTickets={setTickets} />
            :
            <PlayAfter teamColor={teamColor} tickets={tickets} teamName={team} type={type} product_code={product_code} productValiationId={productValiationId} productValiationName={productValiationName} rarityName={rarityName} rareEffect={rareEffect} setProductValiationId={setPoductValiationId} setRarityName={setRarityName} setProductValiationName={setProductValiationName} setTickets={setTickets} setRareEffect={setRareEffect} />
        }
      </div>
    );
  }

};

export default GachaPlay; 