import React from 'react';
import { FormValues } from './Delivery'

type DeliveryFormProps = {
  formValues: FormValues,
}

export const DeliveryComplete: React.FC<DeliveryFormProps> = ({ formValues }) => {

  return (
    <>
    <p className="topTitle">発送完了</p>
      <div className="px-1 mainSection">
        <p className="fs-description">
          この度はオンサイトカードOnlineをご利用いただきありがとうございます。<br />
          商品の発送依頼を受け付けました。
        </p>
        <p className="fs-description my-3">
          お客様のメールアドレス宛に
          自動返信の内容確認メールを
          お送りいたしましたので、ご確認ください。
        </p>
        <div className="border rounded-3 p-3">
          <p className="fs-description mt-2">【郵便番号】{formValues.postcode}</p>
          <p className="fs-description mt-2">【住所】{formValues.address}</p>
          <p className="fs-description my-2">お届け予定日：<span className="text-danger">7営業日内</span>でお届け予定。</p>
        </div>
        <p className="my-3">発送先住所の変更を行う場合は<a target="_blank" rel="noreferrer"
          href="https://www.pbridge.net/contact">こちら</a>からお問い合わせをお願いします。
        </p>
        <a href="/" className="btn btn-primary min-w-200 mx-auto my-2 px-2 py-1">トップへ戻る</a>
      </div>
    </>
  );
};