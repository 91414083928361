
import React, { useEffect, useState } from 'react';
import RatioButton from '../../subComponents/gacha/RatioButton'
import { LoadingScreen } from '../loading/LoadingScreen'
import PurchaseButton from '../../subComponents/gacha/PurchaseButton'
import { Link } from 'react-router-dom';
import '../../../styles/gacha/result.sass';
type PlayAfterProps = {
  teamColor: string,
  tickets: number,
  teamName: string,
  type: string,
  product_code: string,
  productValiationId: string,
  productValiationName: string,
  rarityName: string,
  rareEffect: string,
  setProductValiationId: React.Dispatch<React.SetStateAction<string>>,
  setRarityName: React.Dispatch<React.SetStateAction<string>>,
  setProductValiationName: React.Dispatch<React.SetStateAction<string>>,
  setTickets: React.Dispatch<React.SetStateAction<number>>,
  setRareEffect: React.Dispatch<React.SetStateAction<string>>,
}

export const PlayAfter: React.FC<PlayAfterProps> = ({ teamColor, tickets, teamName, type, product_code, productValiationId, productValiationName, rarityName, rareEffect, setProductValiationId, setRarityName, setProductValiationName, setTickets, setRareEffect }) => {
  const [isClicked, setIsClicked] = useState(false);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <div className='mainSection'>
      <div id="gacha-result" onClick={() => setIsClicked(true)}>
        <div className="background-effect" />
        <p className={`getText fs-title fw-bold text-center ${rarityName === "N" ? 'normal' : 'rare'} ${isClicked ? 'visible' : 'invisible'}`} id="result-title">
          選手獲得!!
        </p>
        <div className="card-drow-animation">
          <div className={`cardArea mb-3 ${rareEffect === "RareEffect1" ? 'text-shadow-rare' : 'text-shadow-normal'} ${isClicked ? 'flipped' : 'flip'}`}>
            <img className='back' src={`/image/${teamName}/${product_code}/${String(productValiationId).padStart(8, '0')}.jpg`} alt={productValiationName} />
            <img className="front" alt="front" src={`/image/${teamName}/${product_code}/${type}-result.jpg`} />
          </div>
          <p className={`text ${rareEffect === "RareEffect2" ? 'text-shadow-rare' : ''} ${isClicked ? 'invisible' : 'visible'}`} id="touched">タッチしてください</p>
        </div>
        <p id="result-name" className={`getText fs-title2 fw-bold text-center  ${rarityName === "N" ? 'normal' : 'rare'} ${isClicked ? 'visible' : 'invisible'}`}>
          {rarityName}<br />
          {productValiationName}
        </p>
      </div >
      <div className={`bg-cleargray w-250 mx-auto mt-2 p-2 visible`}>
        {
          Number(tickets) > 0 ?
            <>
              <span className='d-block text-center'>所持チケット：{tickets}</span>
              <PurchaseButton
                teamColor={teamColor}
                product_code={product_code}
                setRareEffect={setRareEffect}
                setProductValiationId={setProductValiationId}
                setProductValiationName={setProductValiationName}
                setRarityName={setRarityName}
                setTickets={setTickets}
                setLoading={setLoading}
                setIsClicked={setIsClicked}
              />
              <RatioButton teamColor={teamColor} product_code={product_code}></RatioButton>
            </>
            :
            <>
              <span className="d-block text-center text-danger fw-bold">所持チケット：{tickets}</span>
              <Link className={`bg-${teamColor}-primary btn w-75 mx-auto m-2`} to="/ticket">チケットを購入する</Link>
            </>
        }
        {/* <a className="btn btn-secondary w-250 mx-auto my-2 py-1" href="{{ url('/album') }}">アルバムを見る</a> */}
        <p className="text-center"><a href="/transaction">特定商取引法に基づく表記</a></p>
      </div >
    </div>
  );
}

export default PlayAfter;
