import React, { useState, useEffect } from 'react';
import { LoadingScreen } from '../loading/LoadingScreen'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import axios from 'axios';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import { Document, Packer, Paragraph, Table as DocxTable, TableRow, TableCell, WidthType, VerticalAlign, ISectionOptions } from 'docx';
import { saveAs } from 'file-saver';
import { searchDeliveryData } from '../../utils/getData/admin/Delivery';
import DeliveryUpdateButton from '../../subComponents/management/DeliveryUpdateButton';
import { validateSearchDeliveryTime } from '../../utils/validate/Validate';
import DeliverySearchHelpButton from '../../subComponents/management/DeliverySearchHelpButton';
const apiUrl = process.env.REACT_APP_BACKEND_API_URL;

export interface ProductVariation {
  id: number;
  name: string;
  rarity: string;
  back_number: string;
  team_name: string;
  product_id: string;
}

export interface Delivery {
  id: string;
  recipient_name: string;
  email: string;
  phone_number: string;
  address: string;
  postcode: string;
  status: number;
  create: string;
  update: string;
  deliveryproductvariation_set: {
    [productName: string]: ProductVariation[];
  };
}

const DeliveryManagement: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<boolean>(false);
  const [activeTabKey, setActiveTabKey] = useState('notDelivered');
  const [deliveredData, setDeliveredData] = useState<Delivery[]>([]);
  const [notDeliveredData, setNotDeliveredData] = useState<Delivery[]>([]);
  const [workingDeliveryData, setWorkingDeliveryData] = useState<Delivery[]>([]);
  const [searchedDeliveryData, setSearchedDeliveryData] = useState<Delivery[]>([]);
  const [checkedDeliveries, setCheckedDeliveries] = useState<{ [key: string]: boolean }>({});
  const [checkedDeliveriesCount, setCheckedDeliveriesCount] = useState<number>();
  const [searchStartTime, setSearchStartTime] = useState<string>("");
  const [searchEndTime, setSearchEndTime] = useState<string>("");
  const [searchName, setSearchName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const deliveryStatus = ["未発送", "発送済み", "仕掛り"];
  const searchStartTimeError: string = validateSearchDeliveryTime(searchStartTime);
  const searchEndTimeError: string = validateSearchDeliveryTime(searchEndTime);
  const [searchTimeError, setSearchTimeError] = useState<string>("");

  const isDisabledSearch = ((searchStartTime === "" && searchEndTime === "" && email === "" && searchName === "") || searchTimeError !== "");
  const handleSelectTab = (key: string | null) => {
    if (key === null) {
      return;
    }
    resetCheckBox();
    setActiveTabKey(key);
  };

  const handleCheckboxChange = (id: string, isChecked: boolean) => {
    setCheckedDeliveries(prev => ({ ...prev, [id]: isChecked }));
  };

  const handleAllChecboxChange = (deliveryData: Delivery[]) => {
    const updatedData: { [key: string]: boolean } = {};

    deliveryData.forEach(delivery => {
      updatedData[delivery.id] = true;
    });
    setCheckedDeliveries(updatedData);
  }

  const resetCheckBox = () => {
    setCheckedDeliveries({});
  }

  const handleSeachDelivery = async (searchStartTime: string, searchEndTime: string) => {
    setLoading(true);
    
    // 発送日時の検索範囲を制御
    if(searchStartTime && searchEndTime){
      if(Number(searchEndTime) - Number(searchStartTime) > 3000001){
        alert("発送日時の検索範囲は3日間以内にしてください");
        setLoading(false);
        return;
      }
    }

    try {
      const response = await searchDeliveryData(searchName, email, searchStartTime, searchEndTime);
      setSearchedDeliveryData(response);
      setLoading(false);
    } catch {
      setSearchTimeError("検索に失敗しました。ページを更新してください。日時検索の範囲は3日間以内に収まるようにしてください。")
      setLoading(false);
    }
  }

  const fetchDeliveryData = async (status: string) => {
    setLoading(true);
    try {
      const postData = { status: status };
      const response = await axios.post<Delivery[]>(`${apiUrl}/maintenance/getDeliveryListAdmin/`, postData, { withCredentials: true });
      if (!response) {
        alert("データの取得に失敗しました。ページを更新してください。");
        return;
      }
      if (response.status === 200) {
        if (status === "delivered") {
          setDeliveredData(response.data);
        } else if (status === "working") {
          setWorkingDeliveryData(response.data);
        } else if (status === "notDelivered") {
          setNotDeliveredData(response.data);
        }
      } else if (response.status === 401) {
        alert("認証情報の有効期限が切れました。ページを更新してください。");
      } else {
        alert("データ取得に失敗しました。ページを更新してください。");
      }
      resetCheckBox();
      setLoading(false);
    } catch (error) {
      alert("データ取得に失敗しました。ページを更新してください。");
      setError(true);
    }
  };

  const getTotalProductVariations = (deliveryProductVariationSet: { [productName: string]: ProductVariation[] }) => {
    return Object.values(deliveryProductVariationSet).reduce((total, variations) => total + variations.length, 0);
  };

  const generateCSV = (data: Delivery[]) => {
    // CSVのヘッダー
    const headers = [
      "お届け先郵便番号",
      "お届け先氏名",
      "お届け先敬称",
      "お届け先住所1行目",
      "お届け先住所2行目",
      "お届け先住所3行目",
      "お届け先住所4行目",
      "内容品"
    ];

    // CSVの各行を生成
    const rows = data.map(item => [
      item.postcode.replace(/\r?\n/g, ' '),
      item.recipient_name.replace(/\r?\n/g, ' '),
      "様",
      item.address.replace(/\r?\n/g, ' '),
      "",
      "",
      "",
      "オンサイトカード"
    ]);

    // ヘッダーと行データを結合し、CSV文字列を生成
    const csvContent = [
      headers.join(","),
      ...rows.map(e => e.join(","))
    ].join("\n");

    return csvContent;
  };

  // ダウンロード用の関数
  const downloadCSV = (csvContent: string, filename = "data.csv") => {
    const BOM = "\uFEFF"; // UTF-8のBOM
    const blob = new Blob([BOM + csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);

    link.href = url;
    link.download = filename;
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleCsvDownload = () => {
    const checkedIds = Object.keys(checkedDeliveries).filter(id => checkedDeliveries[id]);
    const ckeckedNotDeliveredData = notDeliveredData.filter(item => checkedIds.includes(item.id.toString()));
    const csv = generateCSV(ckeckedNotDeliveredData);
    downloadCSV(csv, "delivery_data.csv");
  };

  const generateDocxWithTables = async (tableIds: string[]): Promise<void> => {
    const sections: ISectionOptions[] = [];

    for (const tableId of tableIds) {
      const tableElement = document.getElementById(tableId);

      if (tableElement) {
        const rows = tableElement.querySelectorAll('tr');
        const tableRows: TableRow[] = Array.from(rows).map((row: Element, rowIndex) => {
          const cells = row.querySelectorAll('th, td');
          const tableCells: TableCell[] = Array.from(cells).map((cell: Element, cellIndex) => {
            const cellHtml = cell.innerHTML.replace(/<br>/g, '\n');
            const paragraphs = cellHtml.split("\n").map(text => new Paragraph({ text }));

            // 列に応じた幅を設定
            let cellWidth = 3000; // デフォルトの幅
            if (cellIndex === 1) { // 2列目の場合
              cellWidth = 7000;
            }

            return new TableCell({
              children: paragraphs,
              verticalAlign: VerticalAlign.CENTER,
              width: {
                size: cellWidth,
                type: WidthType.DXA
              }
            });
          });
          return new TableRow({ children: tableCells });
        });
        sections.push({
          properties: {},
          children: [
            new DocxTable({
              rows: tableRows,
              width: {
                size: 100,
                type: WidthType.PERCENTAGE,
              },
            }),
          ],
        });
      }
    }
    const doc = new Document({ sections });
    const blob = await Packer.toBlob(doc);
    saveAs(blob, 'all-tables.docx'); // saveAsの使用例を追加
  };

  const handleDownloadDocx = async () => {
    const checkedIds = Object.keys(checkedDeliveries).filter(id => checkedDeliveries[id]);
    await generateDocxWithTables(checkedIds);
  };

  useEffect(() => {
    const count = Object.keys(checkedDeliveries).reduce((acc, key) => {
      return acc + (checkedDeliveries[key] ? 1 : 0);
    }, 0)
    setCheckedDeliveriesCount(count);
  }, [checkedDeliveries]);

  const renderTable = (data: Delivery[]) => {
    const table = data.map((item, index) => (
      <div key={index}>
        <div className='d-flex justify-content-evenly'>
          <div className=' me-2'>
            メールアドレス：{item.email} <br />
            電話番号　　　：{item.phone_number}
          </div>
          <div className='text-end'>
            発送依頼日時：{item.create} <br />
            最終更新日時：{item.update}
          </div>
        </div>

        <div className='d-flex justify-content-end m-2'>
          <div
            className='d-flex justify-content-end p-2'
            style={{
              backgroundColor: checkedDeliveries[item.id] ? '#9fc3e3' : 'transparent' // チェックされている場合は背景色をグレーに、そうでなければ透明に設定
            }}
          >
            <input type="checkbox" onChange={(e) => handleCheckboxChange(item.id, e.target.checked)} checked={checkedDeliveries[item.id] || false} />
          </div>
          <Table id={item.id} className='m-0' striped bordered hover data-toggle="tooltip" data-placement="top" title={`発送ID(管理者向け)：${item.id} 発送ステータス：${deliveryStatus[item.status]} メールアドレス：${item.email} 電話番号：${item.phone_number}`}>
            <tbody className="fs-body align-middle text-center">
              <tr>
                <td className="w-25">注文番号</td>
                <td>{item.create.replace(/-| |:/g, '')} </td>
              </tr>
              <tr>
                <td className="w-25">お名前</td>
                <td>{item.recipient_name} 様</td>
              </tr>
              <tr>
                <td className="w-25">ご住所</td>
                <td>{item.postcode} <br /> {item.address}</td>
              </tr>
              <tr>
                <td className="w-25">発送商品</td>
                <td className="p-0">
                  {Object.entries(item.deliveryproductvariation_set).map(([productName, productVariations], index) => (
                    <React.Fragment key={productName + index}>
                      {productName}<br />
                      {
                        productVariations.map((variation, idx) => (
                          <React.Fragment key={variation.id + '-' + idx}>
                            [{variation.id}] {variation.rarity} {variation.name} 裏面画像番号：{variation.back_number || 'チームロゴ'} <br />
                          </React.Fragment>
                        ))
                      }
                    </React.Fragment>
                  ))}
                </td>
              </tr>
              <tr>
                <td className="w-25">注文数合計</td>
                <td className="text-end">{getTotalProductVariations(item.deliveryproductvariation_set)}枚</td>
              </tr>
            </tbody>
          </Table>
        </div>
      </div>
    ));
    return table;
  }

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <div>
      <Tabs
        id="controlled-tab-delivery-management"
        onSelect={handleSelectTab}
        activeKey={activeTabKey}
        className="mb-3"
        fill
      >
        <Tab eventKey="notDelivered" title="未発送">
          <div className='d-flex justify-content-end my-3'>チェック済み項目数：{checkedDeliveriesCount}</div>
          <div className='d-flex justify-content-end'>
            <Button onClick={handleCsvDownload} className='ms-2'>クリックポスト用csvをDL</Button>
            <Button onClick={handleDownloadDocx} className='ms-2'>docxをDL</Button>
          </div>
          <div className='d-flex justify-content-end my-2'>
            <Button onClick={() => handleAllChecboxChange(notDeliveredData)} className='ms-2'>全てをチェック</Button>
            <DeliveryUpdateButton label="仕掛りに変更" afterStatus="working" checkedIds={Object.keys(checkedDeliveries).filter(id => checkedDeliveries[id])} currentDeliveryData={notDeliveredData} setCurrentDeliveryData={setNotDeliveredData} setTargetDeliveryData={setWorkingDeliveryData} resetCheckBox={resetCheckBox} setError={setError} />
            <Button onClick={() => fetchDeliveryData("notDelivered")} className='ms-2'>最新データ取得</Button>

          </div>
          {renderTable(notDeliveredData)}
        </Tab>
        <Tab eventKey="working" title="仕掛り">
          <div className='d-flex justify-content-end my-3'>チェック済み項目数：{checkedDeliveriesCount}</div>
          <div className='d-flex justify-content-end mb-3'>
            <Button onClick={() => handleAllChecboxChange(workingDeliveryData)} className='ms-2'>全てをチェック</Button>
            <DeliveryUpdateButton label="未発送に変更" afterStatus="notDelivered" checkedIds={Object.keys(checkedDeliveries).filter(id => checkedDeliveries[id])} currentDeliveryData={workingDeliveryData} setCurrentDeliveryData={setWorkingDeliveryData} setTargetDeliveryData={setNotDeliveredData} resetCheckBox={resetCheckBox} setError={setError} />
            <DeliveryUpdateButton label="発送済みに変更" afterStatus="delivered" checkedIds={Object.keys(checkedDeliveries).filter(id => checkedDeliveries[id])} currentDeliveryData={workingDeliveryData} setCurrentDeliveryData={setWorkingDeliveryData} setTargetDeliveryData={setDeliveredData} resetCheckBox={resetCheckBox} setError={setError} />

            <Button onClick={() => fetchDeliveryData("working")} className='ms-2'>最新データ取得</Button>
          </div>
          {renderTable(workingDeliveryData)}
        </Tab>
        <Tab eventKey="delivered" title="発送済み">
          <div className='d-flex justify-content-end my-3'>チェック済み項目数：{checkedDeliveriesCount}</div>
          <div className='d-flex justify-content-end mb-3'>
            <Button onClick={() => handleAllChecboxChange(deliveredData)} className='ms-2'>全てをチェック</Button>
            <DeliveryUpdateButton label="未発送に変更" afterStatus="notDelivered" checkedIds={Object.keys(checkedDeliveries).filter(id => checkedDeliveries[id])} currentDeliveryData={deliveredData} setCurrentDeliveryData={setDeliveredData} setTargetDeliveryData={setNotDeliveredData} resetCheckBox={resetCheckBox} setError={setError} />
            <DeliveryUpdateButton label="仕掛りに変更" afterStatus="working" checkedIds={Object.keys(checkedDeliveries).filter(id => checkedDeliveries[id])} currentDeliveryData={deliveredData} setCurrentDeliveryData={setDeliveredData} setTargetDeliveryData={setWorkingDeliveryData} resetCheckBox={resetCheckBox} setError={setError} />
            <Button onClick={() => fetchDeliveryData("delivered")} className='ms-2'>最新データ取得</Button>
          </div>
          {renderTable(deliveredData)}
        </Tab>
        <Tab eventKey="search" title="発送検索">
          <div className='d-flex justify-content-end align-items-center my-3'>
            <div className='mx-2'>
              <p className='my-1'>名前</p>
              <input className='my-auto max-w-150' defaultValue={searchName} onChange={(e) => setSearchName(e.target.value)} />
            </div>
            <div className='mx-2'>
              <p className='my-1'>メールドレス</p>
              <input className='my-auto max-w-150' defaultValue={email} type="email" onChange={(e) => setEmail(e.target.value)} />
            </div>
            <div className='mx-2'>
              <p className='my-1'>発送日時
                <span className='text-danger'>
                  {searchTimeError ? (<><br />{searchTimeError}</>) : (<></>)}
                  {searchStartTimeError && searchEndTimeError && searchStartTimeError === searchEndTimeError ? (
                    // 両方のエラー内容が同じ場合
                    <><br />{searchStartTimeError}</>
                  ) : (
                    // エラー内容が異なる場合
                    <>
                      {searchStartTimeError && (<><br />{searchStartTimeError}</>)}
                      {searchEndTimeError && (<><br />{searchEndTimeError}</>)}
                    </>
                  )}
                </span>
              </p>
              <input className='my-auto max-w-150' placeholder="20240102123045" defaultValue={searchStartTime} type="text" maxLength={14} onChange={(e) => setSearchStartTime(e.target.value)} />
              <div className='mx-1 d-inline'>～</div>
              <input className='my-auto max-w-150' placeholder="20240102123045" defaultValue={searchEndTime} type="text" maxLength={14} onChange={(e) => setSearchEndTime(e.target.value)} />
            </div>
            <Button onClick={() => handleSeachDelivery(searchStartTime, searchEndTime)} className='ms-2' disabled={isDisabledSearch}>データ取得</Button>
            <DeliverySearchHelpButton />
          </div>
          {renderTable(searchedDeliveryData)}
        </Tab>
      </Tabs>
    </div >
  );
};

export default DeliveryManagement; 